import { computed, onUnmounted, ref } from 'vue';
import { dscvrApi } from '@/shared/api';
import { WalletSignMessageError } from '@solana/wallet-adapter-base';
import { useWalletStore } from '../store';
import type { PairDeeplinkParams } from '../types/pairing';
import { WalletPairError } from '../types/error';
import { useVerifyUserMutation } from '../../api/use-verify-user.mutation';
import { useGetOrCreateUserMutation } from '../../api/use-get-or-create-user.mutation';
import { useGetMyWalletsQuery } from '../../api/use-get-my-wallets.query';
import { useUnpairWalletMutation } from '../../api/use-unpair-wallet.mutation';
import { usePairWalletDeepLinkMutation } from '../../api/use-pair-wallet-deep-link.mutation';
import { usePairWalletMutation } from '../../api/use-pair-wallet.mutation';
import { isUserRejectionSolanaError } from '@/shared/lib';

export const useWalletPairing = () => {
  const MAX_WAIT_ATTEMPTS = 24; // 2 minutes
  const WAIT_FOR_PAIR_INTERVAL = 5000;
  let waitTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  const getMyWalletsQueryEnabled = ref(false);

  const walletStore = useWalletStore();
  const { mutateAsync: verifyUser } = useVerifyUserMutation();
  const { mutateAsync: getOrCreateUser } = useGetOrCreateUserMutation();
  const {
    data: pairedWallets,
    isFetching: isFetchingPairedWallets,
    refetch: refetchMyWallets,
  } = useGetMyWalletsQuery(getMyWalletsQueryEnabled);
  const { mutateAsync: mutatePairWalletDeepLink } =
    usePairWalletDeepLinkMutation();
  const { mutateAsync: mutatePairWallet } = usePairWalletMutation();
  const { mutateAsync: mutateUnpairWallet } = useUnpairWalletMutation();

  const currentPairingWallet = computed(() => walletStore.currentPairingWallet);
  const isPairing = computed(() => !!currentPairingWallet.value);

  const fetchPairedWallets = async () => {
    await verifyUser();
    getMyWalletsQueryEnabled.value = true;
    return await refetchMyWallets();
  };

  const getNonce = async () => {
    await verifyUser();
    return await getOrCreateUser();
  };

  const setPairingWallet = (wallet: dscvrApi.wallet.WalletType) => {
    walletStore.currentPairingWallet = wallet;
  };

  const clearPairingWallet = () => {
    walletStore.currentPairingWallet = undefined;
  };

  const evmPairedWallets = computed(() => {
    return pairedWallets.value.filter((wallet) =>
      // Polygon is EVM compatible
      wallet.networks.find((network) => network.slug === 'polygon'),
    );
  });

  const firstPairedSolanaWallet = computed(() =>
    pairedWallets.value.find(
      ({ walletChainType }) => walletChainType === 'solana',
    ),
  );

  const primarySolanaWallet = computed(() =>
    pairedWallets.value.find(({ isPrimary }) => isPrimary),
  );

  const solanaPairedWallets = computed(() => {
    return pairedWallets.value.filter(({ networks }) =>
      networks.find((network) => network.slug === 'solana'),
    );
  });

  const finalizeWalletMobilePair = async (
    walletType: dscvrApi.wallet.WalletType,
    payload: dscvrApi.wallet.LinkWalletDto,
    { dscvrPk, otpCode }: Omit<PairDeeplinkParams, 'nonce'>,
  ) => {
    try {
      setPairingWallet(walletType);
      const wallet = await mutatePairWalletDeepLink({
        ...payload,
        dscvrPk,
        otpCode,
      });

      return !!wallet;
    } catch (error) {
      if (
        error instanceof WalletSignMessageError &&
        isUserRejectionSolanaError(error)
      ) {
        return false;
      }
      console.error(error);
      throw new WalletPairError();
    } finally {
      clearPairingWallet();
    }
  };

  const pairWallet = async (
    walletType: dscvrApi.wallet.WalletType,
    payload: dscvrApi.wallet.LinkWalletDto,
  ) => {
    try {
      setPairingWallet(walletType);
      await verifyUser();
      const wallet = await mutatePairWallet(payload);
      return !!wallet;
    } catch (error) {
      if (
        error instanceof WalletSignMessageError &&
        isUserRejectionSolanaError(error)
      ) {
        return false;
      }
      console.error(error);
      throw new WalletPairError();
    } finally {
      clearPairingWallet();
    }
  };

  const unpairWallet = async (address: string) => {
    try {
      await verifyUser();
      await mutateUnpairWallet(address);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const waitForMobileWalletToPair = async (
    walletType: dscvrApi.wallet.WalletType,
  ) => {
    if (waitTimer) {
      clearInterval(waitTimer);
    }

    setPairingWallet(walletType);
    let attempts = 0;
    await verifyUser();
    return new Promise<boolean>((resolve) => {
      waitTimer = setInterval(async () => {
        const { data: wallets } = await refetchMyWallets();
        if (wallets?.some((wallet) => wallet.walletType.slug === walletType)) {
          clearInterval(waitTimer);
          clearPairingWallet();
          resolve(true);
          return;
        }

        attempts++;
        if (attempts >= MAX_WAIT_ATTEMPTS) {
          clearInterval(waitTimer);
          clearPairingWallet();
          resolve(false);
        }
      }, WAIT_FOR_PAIR_INTERVAL);
    });
  };

  const stopWaitingForMobileWalletToPair = () => {
    clearPairingWallet();
    if (waitTimer) {
      clearInterval(waitTimer);
    }
  };

  onUnmounted(stopWaitingForMobileWalletToPair);

  return {
    isFetchingPairedWallets,
    pairedWallets,
    currentPairingWallet,
    isPairing,
    evmPairedWallets,
    firstPairedSolanaWallet,
    solanaPairedWallets,
    primarySolanaWallet,
    setPairingWallet,
    clearPairingWallet,
    fetchPairedWallets,
    finalizeWalletMobilePair,
    pairWallet,
    unpairWallet,
    waitForMobileWalletToPair,
    stopWaitingForMobileWalletToPair,
    getNonce,
  };
};
