import { Connection } from '@solana/web3.js';
import { config } from '@/shared/lib';

const createRPCConnection = (mainnet = true) => {
  const helloMoonBearer = config.HELLOMOON_API_KEY;

  const helloMoonEndpoint = helloMoonBearer
    ? `https://rpc.hellomoon.io/${helloMoonBearer}`
    : 'https://api.mainnet-beta.solana.com';

  const rpcEndpoint = mainnet
    ? helloMoonEndpoint
    : 'https://api.devnet.solana.com';

  return new Connection(rpcEndpoint);
};

const mainnetConnection = createRPCConnection();
const devnetConnection = createRPCConnection(false);
export const SOLANA_MAINNET_CHAIN_ID = 'solana:101';
export const SOLANA_DEVNET_CHAIN_ID = 'solana:103';
export const SOLANA_ECLIPSE_CHAIN_ID = 'eclipse:101';

export const getConnection = (chainId: string = SOLANA_MAINNET_CHAIN_ID) =>
  chainId === SOLANA_DEVNET_CHAIN_ID ? devnetConnection : mainnetConnection;
