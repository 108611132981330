import {
  WalletConnectionError,
  type WalletName,
} from '@solana/wallet-adapter-base';

export const SOLANA_WALLET_NAMES = {
  Phantom: 'Phantom' as WalletName,
  Solflare: 'Solflare' as WalletName,
  Metamask: 'MetaMask' as WalletName,
  Backpack: 'Backpack' as WalletName,
  BackpackEclipse: 'Backpack Eclipse' as WalletName,
} as const;

export const isUserRejectionSolanaError = (error: Error) => {
  const rejectionMessages = [
    'User rejected the request.',
    'Transaction rejected',
    'Transaction cancelled',
    JSON.stringify({ message: 'Plugin Closed' }), // e.g Backpack
  ];

  return rejectionMessages.includes(error.message);
};

export const isUserConnectRejectionSolanaError = (error: Error) => {
  if (!(error instanceof WalletConnectionError)) {
    return false;
  }
  // Solana returns an empty error message when cancelled by the user, for some wallets
  return !error.message || isUserRejectionSolanaError(error);
};
