import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { Principal } from '@dfinity/principal';

export const useSetUserAgentMutation = () => {
  return useMutation({
    mutationKey: ['set-user-agent-mutation'],
    mutationFn: (params: { agentPrincipal: Principal }) =>
      dscvrIcApi.userSettings.setUserAgent(params.agentPrincipal),
  });
};
