<script setup lang="ts">
  import { onBeforeMount, ref, onMounted } from 'vue';
  import type { UserView } from 'dfx/edge/edge.did';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import type { ChainOption } from '@/utils';
  import { dscvrApi } from '@/shared/api';
  import { useBreakpoint } from '@/shared/model';
  import {
    AccessControl,
    AgentSettings,
    usePreferenceSettings,
  } from '@/entities/user-setting';
  import {
    SOLANA_PAIR_ENABLED_WALLETS,
    useWalletPairing,
  } from '@/entities/wallets';
  import {
    PairedIcpWallets as PairedIcpWalletsComponent,
    PairedPlatforms,
    PairedTwitterAccount,
  } from '@/features/user-settings';
  import {
    PairingEclipseWallet,
    PairingSolanaWallet,
    PairingEvmWallet,
    PairedMatricaWallets,
  } from '@/features/wallets';

  // props and emits are unused, but added to avoid console warnings
  defineProps<{
    username?: string;
    user?: UserView;
  }>();
  defineEmits<{
    (e: 'refresh:user'): void;
  }>();

  const { isSmallerThanMd } = useBreakpoint();
  const { primaryChainOption, primaryChainOptions } = usePreferenceSettings();
  const { pairedWallets, fetchPairedWallets } = useWalletPairing();

  const selectedChainOption = ref<ChainOption>();

  const chainSelectionDropdown = ref(false);
  const closeChainSelectionDropdown = () => {
    chainSelectionDropdown.value = false;
  };

  const setChain = (e: ChainOption) => {
    selectedChainOption.value = e;
    chainSelectionDropdown.value = !chainSelectionDropdown.value;
  };

  onMounted(() => {
    selectedChainOption.value = primaryChainOption.value;
  });

  onBeforeMount(async () => {
    await fetchPairedWallets();
  });
</script>

<template>
  <h1 class="text-2xl leading-7 tracking-[-0.12px] font-bold">Integrations</h1>

  <!-- Wallets -->
  <div class="mt-8">
    <section class="flex items-center justify-between">
      <h2 class="text-xl font-bold leading-7 tracking-[0.2px]">Wallets</h2>
      <div class="max-w-[156px] w-full relative">
        <base-button
          variant="custom"
          custom-classes="flex !justify-start relative w-full rounded-xl border-0 bg-black/32 cursor-pointer py-2 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-gray-785 ring-opacity-80 focus:ring-1 focus:ring-inset focus:ring-gray-785 focus:ring-opacity-80 sm:text-sm sm:leading-6 transition-all duration-300 hover:bg-gray-950 disabled:opacity-75"
          @click="chainSelectionDropdown = !chainSelectionDropdown"
        >
          <template v-if="selectedChainOption">
            <section class="flex items-center justify-start">
              <base-icon
                :name="`chain-${selectedChainOption.logo}`"
                size="size-4"
              />
              <span class="block ml-2 truncate">
                {{ selectedChainOption.title }}
              </span>
            </section>
          </template>
          <template v-else>
            <span class="block text-gray-400 truncate">
              {{ $t('selectSitePrimaryLanguage') }}
            </span>
          </template>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
          >
            <base-icon
              name="chevron-down"
              size="size-4"
              class="text-gray-400"
            />
          </span>
        </base-button>
        <base-dropdown
          v-model:opened="chainSelectionDropdown"
          match-content-width
          placement="bottom-start"
          custom-content-classes="max-h-56 w-full !overflow-auto scrollbar-thin scrollbar-thumb-gray-785 scrollbar-track-gray-950 rounded-xl bg-gray-950 text-base shadow-lg border border-gray-785 border-opacity-50 focus:outline-none sm:text-sm"
        >
          <template #content>
            <div
              v-for="(chainOption, idx) in primaryChainOptions"
              :key="idx"
              class="py-2 pl-3 cursor-pointer select-none pr-9 hover:bg-gray-785"
              :class="{
                'hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785 bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
                  selectedChainOption &&
                  selectedChainOption.id === chainOption.id,
              }"
              @click="
                setChain(chainOption);
                closeChainSelectionDropdown();
              "
            >
              <div class="flex items-center">
                <base-icon :name="`chain-${chainOption.logo}`" size="size-4" />
                <span class="ml-2 truncate">
                  {{ chainOption.title }}
                </span>
              </div>
            </div>
          </template>
        </base-dropdown>
      </div>
    </section>
    <section class="flex flex-col items-start w-full space-y-4 pt-4">
      <paired-icp-wallets-component
        v-if="
          selectedChainOption?.chain === 'icp' ||
          selectedChainOption?.chain === 'all'
        "
        class="w-full"
      />
      <template
        v-if="
          selectedChainOption?.chain === 'sol' ||
          selectedChainOption?.chain === 'all'
        "
      >
        <pairing-solana-wallet
          v-for="walletName in SOLANA_PAIR_ENABLED_WALLETS"
          class="w-full"
          :key="walletName"
          :wallet-name="walletName"
        />
      </template>
      <pairing-eclipse-wallet class="w-full" />
      <pairing-evm-wallet
        v-if="selectedChainOption?.chain === 'all'"
        class="w-full"
        :wallet-type="dscvrApi.wallet.WALLET_TYPES.metamask"
      />
    </section>
  </div>

  <!-- Matrica Wallets -->
  <div class="mt-8">
    <h2 class="text-xl font-bold leading-7 tracking-[0.2px]">
      Matrica Wallets
    </h2>
    <section class="flex flex-col items-start w-full space-y-4">
      <paired-matrica-wallets class="w-full" />
    </section>
  </div>

  <!-- Apps and Social -->
  <div class="mt-8">
    <h2 class="text-xl font-bold leading-7">{{ $t('appsAndSocial') }}</h2>
    <section class="flex flex-col items-start w-full space-y-4">
      <paired-platforms />
      <paired-twitter-account />
    </section>
  </div>

  <!-- Access -->
  <template v-if="pairedWallets.length">
    <div class="mt-8">
      <h2 class="text-xl font-bold leading-7">{{ $t('access') }}</h2>
      <h4 v-if="!isSmallerThanMd" class="my-4">
        {{ $t('accessDescription') }}
      </h4>
      <section
        class="flex flex-col items-start w-full"
        :class="{
          'divide-y divide-gray-700 gap-y-8 divide-y-margin divide-opacity-70 mt-4':
            isSmallerThanMd,
          'gap-y-4': !isSmallerThanMd,
        }"
      >
        <access-control
          v-for="(wallet, index) in pairedWallets"
          :key="wallet.id"
          :wallet="wallet"
          id-prefix="Address"
          :class="{ 'pt-8': index > 0 && isSmallerThanMd }"
        />
      </section>
    </div>
  </template>

  <!-- Agent Settings -->
  <div class="mt-8">
    <h2 class="text-xl font-bold leading-7">Agent Settings</h2>
    <h4 v-if="!isSmallerThanMd" class="my-4">
      Principal of the agent you wish to assign to your account.
    </h4>
    <section class="flex flex-col items-start w-full space-y-4">
      <agent-settings />
    </section>
  </div>
</template>
