<script setup>
  import { onMounted, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { SOCIAL_NETWORKS } from '../common/constants';
  import { matchesLink } from '../utils/general';
  import { useConfirmationDialog } from '@/shared/ui/confirmation';

  const props = defineProps({
    links: {
      type: Object,
      default() {
        return []; // format is [{href:"https://facebook.com..."},{href:"https://twitter.com..."}]
      },
    },
  });

  const emit = defineEmits(['update-links']); // array in format [{href:"https://facebook.com..."},{href:"https://twitter.com..."}]

  const { openConfirmationDialog, closeDialog } = useConfirmationDialog();
  const { t } = useI18n({ useScope: 'global' });

  const getAvailableLinkTypes = () => [
    {
      type: 'Discord',
      icon: SOCIAL_NETWORKS.discord.icon,
      matches: SOCIAL_NETWORKS.discord.matches,
      shown: true,
      alwaysShown: true,
    },
    {
      type: 'Twitter',
      icon: SOCIAL_NETWORKS.twitter.icon,
      matches: SOCIAL_NETWORKS.twitter.matches,
      shown: true,
      alwaysShown: true,
    },
    {
      type: 'Website',
      icon: SOCIAL_NETWORKS.genericWebsite.icon,
      matches: [],
      shown: true,
      alwaysShown: true,
    },
    {
      type: 'Atlas3',
      icon: SOCIAL_NETWORKS.atlas3.icon,
      matches: SOCIAL_NETWORKS.atlas3.matches,
      shown: false,
    },
    {
      type: 'Instagram',
      icon: SOCIAL_NETWORKS.instagram.icon,
      matches: SOCIAL_NETWORKS.instagram.matches,
      shown: false,
      invalid: false,
    },
    {
      type: 'TikTok',
      icon: SOCIAL_NETWORKS.tikTok.icon,
      matches: SOCIAL_NETWORKS.tikTok.matches,
      shown: false,
    },
    {
      type: 'Telegram',
      icon: SOCIAL_NETWORKS.telegram.icon,
      matches: SOCIAL_NETWORKS.telegram.matches,
      shown: false,
      invalid: false,
    },
    {
      type: 'YouTube',
      icon: SOCIAL_NETWORKS.youTube.icon,
      matches: SOCIAL_NETWORKS.youTube.matches,
      shown: false,
    },
    {
      type: 'Twitch',
      icon: SOCIAL_NETWORKS.twitch.icon,
      matches: SOCIAL_NETWORKS.twitch.matches,
      shown: false,
    },
    {
      type: 'Facebook',
      icon: SOCIAL_NETWORKS.facebook.icon,
      matches: SOCIAL_NETWORKS.facebook.matches,
      shown: false,
    },
    {
      type: 'OpenChat',
      icon: SOCIAL_NETWORKS.openChat.icon,
      matches: SOCIAL_NETWORKS.openChat.matches,
      shown: false,
    },
    {
      type: 'DRiP',
      icon: SOCIAL_NETWORKS.drip.icon,
      matches: SOCIAL_NETWORKS.drip.matches,
      shown: true,
    },
  ];

  const linksContextMenu = ref(false);
  const linkTypes = ref([]);

  const shownLinkTypes = computed(() =>
    linkTypes.value.filter((linkType) => linkType.shown),
  );

  const hiddenLinkTypes = computed(() =>
    linkTypes.value.filter((linkType) => !linkType.shown),
  );

  const filteredLinks = computed(() =>
    linkTypes.value.reduce(
      (list, linkType) =>
        linkType.shown && linkType.href.length > 0 && !linkType.invalid
          ? [...list, { href: linkType.href }]
          : list,
      [],
    ),
  );

  const addLinkType = (type) => {
    type.shown = true;

    emit('update-links', filteredLinks.value);
  };

  const removeLinkType = (type) => {
    type.href = '';
    if (!type.alwaysShown) type.shown = false;

    closeDialog();

    emit('update-links', filteredLinks.value);
  };

  const validateLinkType = (linkType) => {
    const isValid =
      !linkType.matches.length || matchesLink(linkType.matches, linkType.href);
    linkType.invalid = linkType.shown && linkType.href.length > 0 && !isValid;

    emit('update-links', filteredLinks.value);
  };

  const loadLinks = () => {
    const website = linkTypes.value.find(({ type }) => type === 'Website');
    props.links.forEach((link) => {
      const foundLinkType =
        linkTypes.value.find(({ matches }) =>
          matchesLink(matches, link.href),
        ) || website;
      foundLinkType.href = link.href;
      foundLinkType.shown = true;
    });
  };

  const updateLinks = () => {
    const availableLinkTypes = getAvailableLinkTypes();
    linkTypes.value = availableLinkTypes.map((link) => ({
      ...link,
      href: '',
      invalid: false,
    }));
    loadLinks();
    emit('update-links', filteredLinks.value);
  };

  const openRemoveLinkDialog = (link) => {
    openConfirmationDialog({
      headerTitle: t('removeLink'),
      contentSlots: {
        default: { template: t('removeLinkLegend') },
        submitLabel: { template: t('remove') },
      },
      submit: () => removeLinkType(link),
    });
  };

  onMounted(() => updateLinks());
</script>

<template>
  <div class="flex flex-col items-center sm:block mt-9">
    <div class="flex justify-between gap-2 items-end">
      <div class="flex-grow">
        <h2 class="mb-2 text-lg font-semibold text-white">Social Links</h2>
        <p class="text-sm text-gray-400">
          Enter to show off your social media presence in the right sidebar.
        </p>
      </div>
      <button
        class="z-10 relative min-w-max btn-tertiary text-sm font-medium outline-none px-4 py-2.5 ml-3 mt-1 rounded-md flex disabled:opacity-50"
        :disabled="hiddenLinkTypes.length == 0"
        @click="linksContextMenu = !linksContextMenu"
      >
        Add Link
        <div
          v-show="linksContextMenu"
          class="absolute bg-gray-800 rounded-lg min-w-60 max-w-max right-0 top-10 z-50"
        >
          <div v-for="linkType in hiddenLinkTypes" :key="linkType.type">
            <div
              class="px-3 py-3 text-base flex items-center hover:bg-gray-700 rounded-lg"
              @click="addLinkType(linkType)"
            >
              <base-icon class="mr-3" size="w-9 h-9" :name="linkType.icon" />
              <span>{{ linkType.type }}</span>
            </div>
          </div>
        </div>
      </button>
    </div>

    <p v-show="!links" class="p-3 mt-3 text-center text-small">
      No added links
    </p>

    <div class="w-full my-5 overflow-y-auto thin-scrollbar overflow-x-hidden">
      <div v-for="linkType in shownLinkTypes" :key="linkType.type">
        <div
          class="relative flex flex-col sm:flex-row sm:items-center gap-3 justify-between py-3 text-white"
        >
          <div class="relative flex items-center min-w-40">
            <base-icon class="mr-3" size="w-9 h-9" :name="linkType.icon" />
            <span class="text-lg">{{ linkType.type }}</span>
          </div>
          <div class="relative flex items-center justify-end w-full">
            <div class="w-full">
              <input
                v-model="linkType.href"
                type="text"
                class="block w-full text-white bg-gray-900 border rounded-md focus:bg-gray-700 focus:ring-0 mr-3"
                :class="
                  linkType.invalid ? 'border-red-600' : 'border-transparent'
                "
                required="required"
                placeholder="Link URL..."
                @keyup="validateLinkType(linkType)"
              />
            </div>
            <div class="flex items-center">
              <div
                class="btn-tertiary flex items-center px-5 py-2 h-9 ml-2 text-sm bg-gray-700 rounded-lg cursor-pointer"
                @click="openRemoveLinkDialog(linkType)"
              >
                <base-icon name="delete" size="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .divider {
    width: 100%;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #374151;
  }
</style>
