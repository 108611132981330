<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { useToast } from '@/shared/model';
  import { Principal } from '@dfinity/principal';
  import { useSetUserAgentMutation } from '../../api/use-set-user-agent.mutation';
  import { useGetUserAgentsQuery } from '../../api/use-get-user-agents.query';
  import { useRemoveUserAgentMutation } from '../../api/use-remove-user-agent.mutation';
  import { Loader } from '@/shared/ui/loader';
  import { useDebounceFn } from '@vueuse/core';

  const { showToast } = useToast();
  const agentPrincipalValue = ref('');

  const { mutate: mutateSetUserAgent, isPending: isPendingSetUserAgent } =
    useSetUserAgentMutation();
  const { mutate: mutateRemoveUserAgent, isPending: isPendingRemoveUserAgent } =
    useRemoveUserAgentMutation();
  const { data: userAgents, refetch: fetchUserAgents } =
    useGetUserAgentsQuery();

  const isPending = computed(
    () => isPendingSetUserAgent.value || isPendingRemoveUserAgent.value,
  );

  const isValidPrincipal = computed(() => {
    if (!agentPrincipalValue.value) {
      return false;
    }
    return Boolean(Principal.fromText(agentPrincipalValue.value));
  });

  const refreshAgents = () => {
    setTimeout(() => {
      fetchUserAgents();
    }, 3000);
  };

  const assign = async () => {
    if (!isValidPrincipal.value) {
      showToast({
        title: 'Invalid Principal',
        type: 'error',
        durationSeconds: 3,
      });
    }

    mutateSetUserAgent(
      {
        agentPrincipal: Principal.fromText(agentPrincipalValue.value),
      },
      {
        onError: () => {
          showToast({
            title: 'Error assigning agent',
            type: 'error',
            durationSeconds: 3,
          });
        },
        onSuccess: () => {
          showToast({
            title: 'Agent assigned successfully',
            type: 'success',
            durationSeconds: 3,
          });
          refreshAgents();
        },
      },
    );
  };

  const remove = async () => {
    mutateRemoveUserAgent(
      {
        agentPrincipal: Principal.fromText(agentPrincipalValue.value),
      },
      {
        onError: () => {
          showToast({
            title: 'Error removing agent',
            type: 'error',
            durationSeconds: 3,
          });
        },
        onSuccess: () => {
          showToast({
            title: 'Agent removed successfully',
            type: 'success',
            durationSeconds: 3,
          });
          refreshAgents();
        },
      },
    );
  };

  const activeUserAgent = computed(() => {
    if (userAgents.value && userAgents.value.length > 0) {
      return userAgents.value[0];
    }
    return null;
  });

  const onDebouncedChange = useDebounceFn(() => {
    // This will throw an error if the principal is invalid
    if (!isValidPrincipal.value) {
      return;
    }
  }, 1000);

  watch(
    activeUserAgent,
    (newUserAgent) => {
      agentPrincipalValue.value = newUserAgent?.toText() || '';
    },
    { immediate: true },
  );
</script>

<template>
  <section class="relative w-full">
    <loader
      v-if="isPending"
      variant="rainbow"
      size="size-10"
      border-width="border"
    />
    <div
      class="flex flex-col w-full border rounded-xl bg-gray-950 border-gray-750"
    >
      <div
        class="flex items-center justify-between p-5 bg-black gap-x-2 bg-opacity-15"
      >
        <div
          class="flex items-center overflow-hidden text-ellipsis whitespace-nowrap w-full"
        >
          <div
            class="flex flex-col w-full ml-3 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            <input
              v-model="agentPrincipalValue"
              type="text"
              class="block w-full mt-1 text-white bg-gray-900 border-none rounded-md focus:bg-gray-700 focus:ring-0"
              maxlength="100"
              placeholder="Principal of agent"
              @change="onDebouncedChange"
            />
          </div>
        </div>
        <section class="flex items-end gap-2">
          <base-button
            v-if="!activeUserAgent"
            variant="secondary"
            size="medium"
            rounded="rounded-xl"
            additional-classes="h-10 text-sm w-21"
            @click="assign"
          >
            <span class="font-medium"> Assign </span>
          </base-button>

          <base-button
            v-else
            variant="tertiary"
            size="medium"
            rounded="rounded-xl"
            additional-classes="h-10 text-sm w-21"
            @click="remove"
          >
            <span class="font-medium"> Remove </span>
          </base-button>
        </section>
      </div>
    </div>
  </section>
</template>
