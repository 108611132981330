import { computed, type ComputedRef, nextTick } from 'vue';
import { useWallet } from 'solana-wallets-vue';
import {
  WalletNotReadyError,
  WalletReadyState,
  type WalletName,
} from '@solana/wallet-adapter-base';
import {
  isUserConnectRejectionSolanaError,
  SOLANA_WALLET_NAMES,
} from '@/shared/lib';
import type { SolanaWallet } from '../types/solana';
import { isExcludedSolanaWallet } from '../../lib/solana/settings';
import { WalletNotInstalledError } from '../types/error';

export const useConnectSolanaWallets = () => {
  const {
    wallet: baseWallet,
    wallets: baseWallets,
    select,
    connect,
    ...base
  } = useWallet();

  const wallet = computed(() => {
    if (!baseWallet.value) return null;
    return {
      id: baseWallet.value.adapter.name,
      ...baseWallet.value,
    };
  });

  const connectedWalletAddress = computed(() => {
    if (!base.publicKey.value) return undefined;
    return base.publicKey.value.toString();
  });

  const wallets: ComputedRef<SolanaWallet[]> = computed(() =>
    baseWallets.value
      .filter(({ adapter }) => !isExcludedSolanaWallet(adapter.name))
      .map((wallet) => ({
        id: wallet.adapter.name,
        ...wallet,
      })),
  );

  const installedWallets = computed(() =>
    wallets.value.filter(
      (wallet) => wallet.readyState === WalletReadyState.Installed,
    ),
  );

  const nonInstalledWallets = computed(() =>
    wallets.value.filter(
      (wallet) => wallet.readyState !== WalletReadyState.Installed,
    ),
  );

  const isExistingWallet = (name: WalletName) => {
    if (name === SOLANA_WALLET_NAMES.BackpackEclipse) {
      return wallets.value.some((w) => w.id === SOLANA_WALLET_NAMES.Backpack);
    } else {
      return wallets.value.some((w) => w.id === name);
    }
  };

  const getWalletName = (name: WalletName) => {
    if (name === SOLANA_WALLET_NAMES.BackpackEclipse) {
      return SOLANA_WALLET_NAMES.Backpack;
    } else {
      return name;
    }
  };

  const selectAndConnect = async (name: WalletName): Promise<boolean> => {
    try {
      const existingWallet = isExistingWallet(name);
      if (!existingWallet) {
        if (
          name === SOLANA_WALLET_NAMES.Backpack ||
          name === SOLANA_WALLET_NAMES.BackpackEclipse
        ) {
          // we redirect the user to the backpack download page
          window.open('https://www.backpack.app/download', '_blank');
        }
        // This is the case for StandardWalletAdapters, which are not in the list of wallets when non installed
        throw new WalletNotInstalledError();
      }

      // We need to disconnect the adapter beforehand, even if it's not connected.
      // Otherwise, an adapter might trigger a disconnect event during the select or connect process.
      // This would cause the value value of wallet.value to be cleared, which would throw a WalletNotSelectedError.
      await wallet.value?.adapter.disconnect();
      // disconnect will print some unnecessary console.error messages
      console.clear();
      select(getWalletName(name));
      // select just set the name of the wallet in local storage, it doesn't actually set the `wallet` variable
      // wallet variable happens to be set after a few ms (because it happens on a WatchEffect),
      // so we need to wait for it to be set (with nextTick), otherwise connect wont work
      await nextTick(connect);

      if (name === SOLANA_WALLET_NAMES.BackpackEclipse) {
        //switch to eclipse network
        await (window as any).backpack.connect({
          chainGenesisHash: 'EAQLJCV2mh23BsK2P9oYpV5CHVLDNHTxY',
        });
      }

      return base.connected.value;
    } catch (error) {
      if (
        isUserConnectRejectionSolanaError(error as Error) ||
        error instanceof WalletNotReadyError
      ) {
        return false;
      }
      throw error;
    } finally {
      // connect will print some unnecessary console.error messages
      // even if the process is successful, so we clear the console to avoid confusion
      console.clear();
    }
  };

  return {
    ...base,
    wallet,
    connectedWalletAddress,
    wallets,
    installedWallets,
    nonInstalledWallets,
    selectAndConnect,
  };
};
