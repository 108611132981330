<script lang="ts" setup>
  import { computed } from 'vue';
  import { shortenString, SOLANA_WALLET_NAMES } from '@/shared/lib';
  import { useUser } from '@/entities/user';
  import { PairButton, usePreferenceSettings } from '@/entities/user-setting';
  import {
    convertWalletNameToWalletType,
    useWalletPairing,
    usePairSolanaWallet,
  } from '@/entities/wallets';

  const emit = defineEmits<{
    (event: 'paired'): void;
    (event: 'unpaired'): void;
  }>();

  const { currentUser } = useUser();
  const { currentPairingWallet, pairedWallets, isFetchingPairedWallets } =
    useWalletPairing();
  const { pair, unpair } = usePairSolanaWallet();

  const eclipseWalletName = SOLANA_WALLET_NAMES.BackpackEclipse;

  const pairedWallet = computed(() =>
    pairedWallets.value.find(
      (wallet) =>
        wallet.walletType.slug ===
        convertWalletNameToWalletType(eclipseWalletName),
    ),
  );

  const mainWalletAddress = computed(() => {
    return pairedWallet.value?.address;
  });

  const isWalletPairing = computed(
    () =>
      currentPairingWallet.value ===
      convertWalletNameToWalletType(eclipseWalletName),
  );

  const togglePair = async () => {
    if (!pairedWallet.value) {
      const success = await pair(currentUser.value, eclipseWalletName);
      if (success) {
        emit('paired');
      }
    } else {
      const success = await unpair(pairedWallet.value);
      if (success) {
        emit('unpaired');
      }
    }
  };
  const iconName = computed(() => {
    return 'multi-wallet-backpack-eclipse';
  });
</script>

<template>
  <pair-button
    :is-loading="isWalletPairing || isFetchingPairedWallets"
    :connected-id="mainWalletAddress"
    @toggle="togglePair"
  >
    <base-icon :name="iconName" size="size-10" />
    <template #name>
      {{ eclipseWalletName }}
    </template>
    <template #connection>
      {{ $t('userSettings.address') }}:
      {{ shortenString(mainWalletAddress || '') }}
    </template>
  </pair-button>
</template>
